import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  customizationList: [],
  isCustomizationLoading: false,
};

const customizationSlice = createSlice({
  name: "settings_store",
  initialState: initialState,
  reducers: {
    setCustomization(state, actions) {
      state.customizationList = actions.payload.customizationList;
    },
    setCustomizationLoading(state, actions) {
      state.isCustomizationLoading = actions.payload.isLoading;
    },
  },
});

export const { setCustomization, setCustomizationLoading } =
  customizationSlice.actions;
export default customizationSlice.reducer;
