import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isSliderOpen: false,
  businessDetailsComplete: true,
  businessLoading: false,
  isBusinessDetailsDirty: false,
};

const settingsSlice = createSlice({
  name: "settings_store",
  initialState: initialState,
  reducers: {
    toggleSlider(state, actions) {
      state.isSliderOpen = actions.payload.open;
    },
    setBusinessDetailsComplete(state, actions) {
      state.businessDetailsComplete = actions.payload.complete;
    },
    setBusinessDataLoading(state, action) {
      state.businessLoading = action.payload.loading;
    },
    setBusinessDetailsDirty(state, action) {
      state.isBusinessDetailsDirty = action.payload.isBusinessDetailsDirty;
    },
  },
});

export const {
  toggleSlider,
  setBusinessDetailsComplete,
  setBusinessDataLoading,
  setBusinessDetailsDirty,
} = settingsSlice.actions;
export default settingsSlice.reducer;
