import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { toggleSlider } from "../../store/reducers/settings_store";
import { useSelector, useDispatch } from "react-redux";
import { SideMenuList } from "./SideMenuList";
import { useEffect } from "react";

export default function Slider() {
  const open = useSelector((state) => state.settingsData.isSliderOpen);
  const dispatch = useDispatch();

  useEffect(() => {}, [open]);

  return (
    <Drawer open={open} onClose={() => dispatch(toggleSlider({ open: false }))}>
      <Box sx={{ width: 250 }}>
        <SideMenuList />
      </Box>
    </Drawer>
  );
}
