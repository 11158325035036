import { createSlice } from "@reduxjs/toolkit";
import { CountriesModel } from "../../models/configModels";
import { TimeModel } from "../../models/configModels";
import { ProgressModel } from "../../models/configModels";
import { ScreenSizeModel } from "../../models/configModels";

let initialState = {
  countryCodes: CountriesModel,
  timeOptions: TimeModel,
  progressBarData: ProgressModel,
  screenSize: ScreenSizeModel,
  sidePanelOpen: false,
  reportsFromDate: "",
  reportsToDate: "",
};

const configsSlice = createSlice({
  name: "configs_store",
  initialState: initialState,
  reducers: {
    setCountryCodes(state, actions) {
      state.countryCodes = actions.payload.countryCodes;
    },
    setProgressBar(state, actions) {
      state.progressBarData = actions.payload.progressBarData;
    },
    setScreenSize(state, actions) {
      state.screenSize.large = actions.payload.large;
      state.screenSize.medium = actions.payload.medium;
      state.screenSize.small = actions.payload.small;
    },
    setSidePanelOpen(state, actions) {
      state.sidePanelOpen = actions.payload.open;
    },
    setReportsDate(state, actions) {
      state.reportsFromDate = actions.payload?.fromDate;
      state.reportsToDate = actions.payload?.toDate;
    },
  },
});

export const {
  setCountryCodes,
  setProgressBar,
  setScreenSize,
  setSidePanelOpen,
  setReportsDate,
} = configsSlice.actions;
export default configsSlice.reducer;
