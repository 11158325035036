import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./reducers/user_store";
import SettingsReducers from "./reducers/settings_store";
import ConfigsReducers from "./reducers/config_store";
import CustomizationReducer from "./reducers/customization_store";
import { combineReducers } from "@reduxjs/toolkit";

const reducers = combineReducers({
  userData: UserReducer,
  settingsData: SettingsReducers,
  configsData: ConfigsReducers,
  customizationData: CustomizationReducer,
});

export const reduxStore = configureStore({
  reducer: reducers,
});
