import { useAxiosMiddleware } from "./useAxiosMiddleware";
import { useSelector } from "react-redux";
import { useRefreshToken } from "./useRefreshToken";
import useLocalStorage from "./useLocalStorage";

export function useMiddlewareValidator() {
  const middlewareValidator = (url, headers) => {
    let validatorResponse = {
      validCall: true,
      isLogOut: false,
      message: "",
    };
    let isValid = validatorResponse;
    let bearerRegex = /^Bearer\s[0-9a-zA-Z\-_\.~\+\/]+=*$/;

    if (!url) {
      isValid = {
        validCall: false,
        isLogOut: false,
        message: "Invalid request!",
      };
    } else if (
      !headers?.Authorization ||
      !bearerRegex.test(headers?.Authorization)
    ) {
      isValid = {
        validCall: false,
        isLogOut: true,
        message: "User session corrupted! Login again to continue",
      };
    } else {
      isValid = validatorResponse;
    }
    return isValid;
  };
  return { middlewareValidator };
}

export function useAxios() {
  const { axios } = useAxiosMiddleware();
  const { getLocalStorageItem } = useLocalStorage();

  const { initializeRefresh } = useRefreshToken();
  const token = useSelector((state) => state.userData.tokens.token);
  const storedToken = getLocalStorageItem("accessToken");
  const defaultHeaders = {
    Authorization: `Bearer ${token ? token : storedToken}`,
  };

  const request = async ({ method, url, payload, customHeaders }) => {
    try {
      let headers = {
        ...defaultHeaders,
        ...customHeaders,
      };
      let data = payload;
      const response = await axios({ method, url, data, headers });
      return Promise.resolve(response);
    } catch (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newToken = await initializeRefresh(error);
        if (!newToken) {
          return Promise.reject(error);
        }
        defaultHeaders["Authorization"] = `Bearer ${newToken}`;
        return request({ method, url, payload });
      }
      return Promise.reject(error);
    }
  };
  return { request };
}
