export const TimeModel = [
  { id: 1, slot: "12:00 AM", value: "00:00" },
  { id: 2, slot: "12:30 AM", value: "00:30" },
  { id: 3, slot: "01:00 AM", value: "01:00" },
  { id: 4, slot: "01:30 AM", value: "01:30" },
  { id: 5, slot: "02:00 AM", value: "02:00" },
  { id: 6, slot: "02:30 AM", value: "02:30" },
  { id: 7, slot: "03:00 AM", value: "03:00" },
  { id: 8, slot: "03:30 AM", value: "03:30" },
  { id: 9, slot: "04:00 AM", value: "04:00" },
  { id: 10, slot: "04:30 AM", value: "04:30" },
  { id: 11, slot: "05:00 AM", value: "05:00" },
  { id: 12, slot: "05:30 AM", value: "05:30" },
  { id: 13, slot: "06:00 AM", value: "06:00" },
  { id: 14, slot: "06:30 AM", value: "06:30" },
  { id: 15, slot: "07:00 AM", value: "07:00" },
  { id: 16, slot: "07:30 AM", value: "07:30" },
  { id: 17, slot: "08:00 AM", value: "08:00" },
  { id: 18, slot: "08:30 AM", value: "08:30" },
  { id: 19, slot: "09:00 AM", value: "09:00" },
  { id: 20, slot: "09:30 AM", value: "09:30" },
  { id: 21, slot: "10:00 AM", value: "10:00" },
  { id: 22, slot: "10:30 AM", value: "10:30" },
  { id: 23, slot: "11:00 AM", value: "11:00" },
  { id: 24, slot: "11:30 AM", value: "11:30" },
  { id: 25, slot: "12:00 PM", value: "12:00" },
  { id: 26, slot: "12:30 PM", value: "12:30" },
  { id: 27, slot: "01:00 PM", value: "13:00" },
  { id: 28, slot: "01:30 PM", value: "13:30" },
  { id: 29, slot: "02:00 PM", value: "14:00" },
  { id: 30, slot: "02:30 PM", value: "14:30" },
  { id: 31, slot: "03:00 PM", value: "15:00" },
  { id: 32, slot: "03:30 PM", value: "15:30" },
  { id: 33, slot: "04:00 PM", value: "16:00" },
  { id: 34, slot: "04:30 PM", value: "16:30" },
  { id: 35, slot: "05:00 PM", value: "17:00" },
  { id: 36, slot: "05:30 PM", value: "17:30" },
  { id: 37, slot: "06:00 PM", value: "18:00" },
  { id: 38, slot: "06:30 PM", value: "18:30" },
  { id: 39, slot: "07:00 PM", value: "19:00" },
  { id: 40, slot: "07:30 PM", value: "19:30" },
  { id: 41, slot: "08:00 PM", value: "20:00" },
  { id: 42, slot: "08:30 PM", value: "20:30" },
  { id: 43, slot: "09:00 PM", value: "21:00" },
  { id: 44, slot: "09:30 PM", value: "21:30" },
  { id: 45, slot: "10:00 PM", value: "22:00" },
  { id: 46, slot: "10:30 PM", value: "22:30" },
  { id: 47, slot: "11:00 PM", value: "23:00" },
  { id: 48, slot: "11:30 PM", value: "23:30" },
];

export const CountriesModel = [
  {
    id: 0,
    code: "+64",
    country: "New Zealand",
  },
  {
    id: 2,
    code: "+61",
    country: "Australia",
  },
  {
    id: 3,
    code: "+91",
    country: "India",
  },
];

export const ProgressModel = {
  business: false,
  truck: false,
  schedule: false,
  menu: false,
  userRoles: false,
};

export const ScreenSizeModel = {
  large: true,
  medium: false,
  small: false,
};

export const ReportsSortModel = [
  {
    id: 1,
    sort: "date",
    tittle: "Date",
  },
  {
    id: 2,
    sort: "OrderCount",
    tittle: "Order Count",
  },
  {
    id: 3,
    sort: "sumTotalPrice",
    tittle: "Sales",
  },
];
