import { useDispatch, useSelector } from "react-redux";
import useLogout from "../hooks/useLogout";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/img/logo.png";
import logoutImg from "../assets/img/logout.svg";
import hamMenuIcn from "../assets/img/menu-icons/menu-setup-icon.svg";
import help from "../assets/img/help.svg";
import homeIcon from "../assets/img/home-icon.png";
import { toggleSlider } from "../store/reducers/settings_store";
import { useEffect, useRef, useState } from "react";

export const Header = () => {
  const loggedIn = useSelector((state) => state.userData.loggedIn);
  const screenSize = useSelector((state) => state.configsData.screenSize);
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const appRoute = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [appRoute.pathname]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  //Help Popup Open/Close

  return (
    <div className="navbar-section d-flex">
      <div className="container d-flex">
        <div className="navbar-logo">
          {screenSize.small ? (
            <button
              className="ham-menu-icon"
              onClick={() => dispatch(toggleSlider({ open: true }))}
            >
              <img src={hamMenuIcn} className="img-fluid" alt="Menus" />
            </button>
          ) : null}
          <img src={logo} className="img-fluid" alt="Logo" />
        </div>
        <div className="d-flex navbar-action home-help " ref={dropdownRef}>
          {loggedIn ? (
            <>
              <div className="d-flex help-flex" onClick={toggleDropdown}>
                <img src={help} />
                <p>Help</p>
              </div>
              <div className="d-flex help-flex" onClick={() => logout()}>
                <img src={logoutImg} />
                <p>Log out</p>
              </div>{" "}
            </>
          ) : (
            <div className="home-need-nav">
              {appRoute.pathname !== "/home" && (
                <NavLink to="home">
                  <img src={homeIcon} alt="Home" width={20} height={17} />
                </NavLink>
              )}
              <NavLink onClick={toggleDropdown}>Need Help?</NavLink>
            </div>
          )}
          {isOpen && (
            <div className="help-popup">
              <span>
                Email to{" "}
                <a
                  href="mailto:contactus@cliqmenu.com"
                  className="highlight-txt"
                >
                  contactus@cliqmenu.com
                </a>{" "}
                we will get back to you soon.{" "}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
