import { Outlet } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const AuthLayout = () => {
  return (
    <div className="body-wrapper">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default AuthLayout;
