import CryptoJS from "crypto-js";
import useLogout from "./useLogout";

export default function useLocalStorage() {
  const { logout } = useLogout();
  const setLocalStorageItem = (item, value) => {
    try {
      let storedItem = localStorage.getItem("BMS_enc");
      let itemValue = {};
      if (storedItem) {
        const bytes = CryptoJS.AES.decrypt(
          storedItem,
          process.env.REACT_APP_HASH
        );
        itemValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      itemValue[item] = value;
      const encryptData = CryptoJS.AES.encrypt(
        JSON.stringify(itemValue),
        process.env.REACT_APP_HASH
      ).toString();
      localStorage.setItem("BMS_enc", encryptData);
    } catch (error) {
      console.log("storage fetch error:", error);
      logout();
    }
  };

  const getLocalStorageItem = (item) => {
    try {
      let itemValue = "";
      let encVal = localStorage.getItem("BMS_enc");
      if (!encVal) {
        itemValue = "";
        return itemValue;
      }
      const bytes = CryptoJS.AES.decrypt(encVal, process.env.REACT_APP_HASH);
      itemValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return itemValue[item];
    } catch (error) {
      console.log("storage fetch error:", error);
      logout();
    }
  };

  return { setLocalStorageItem, getLocalStorageItem };
}
