import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProgressGuard = () => {
  const businessId = useSelector(
    (state) => state.userData.businessDetails.BusinessID
  );

  return businessId ? <Outlet /> : <Navigate to="/app/dashboard" />;
};
