import { useEffect } from "react";
import AppRoutes from "./routes/AppRoutes";
import { useDispatch } from "react-redux";
import { setScreenSize } from "./store/reducers/config_store";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  const handleInvalidToken = (e) => {
    if (e.key === "BMS_enc") {
      window.location.reload();
    }
  };

  const updateWindowDimensions = () => {
    let screenVariants = {
      large: true,
      medium: false,
      small: false,
    };
    if (window.innerWidth < 1200 && window.innerWidth > 600) {
      screenVariants.large = false;
      screenVariants.medium = true;
    } else if (window.innerWidth < 600) {
      screenVariants.large = false;
      screenVariants.small = true;
    }
    dispatch(setScreenSize(screenVariants));
  };

  return <AppRoutes />;
}
export default App;
