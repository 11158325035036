import { Outlet } from "react-router-dom";
import { SideMenu } from "../components/SideMenu/SideMenu";
import { Header } from "../components/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setClientToken } from "../store/reducers/user_store";
import useLocalStorage from "../hooks/useLocalStorage";
import useLogout from "../hooks/useLogout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setLogin } from "../store/reducers/user_store";
import { useBusinessDetails } from "../hooks/useBusinessDetails";

export const PageLayout = () => {
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const { getLocalStorageItem } = useLocalStorage();
  const { getBusinessDetails } = useBusinessDetails();

  useEffect(() => {
    getOwnerDetails();
  }, []);

  const getOwnerDetails = () => {
    let storedPhoneNo = getLocalStorageItem("phoneNumber");
    let storedCountryCode = getLocalStorageItem("phoneCode");
    let token = getLocalStorageItem("accessToken");
    let refreshToken = getLocalStorageItem("refreshToken");
    if (!token || !refreshToken) {
      logout();
      return;
    }
    dispatch(setClientToken({ token: token, refreshToken: refreshToken }));
    dispatch(
      setLogin({ phoneNo: storedPhoneNo, phoneCode: storedCountryCode })
    );
    getBusinessDetails();
  };
  return (
    <div>
      <ToastContainer />
      <div className="full-page d-flex">
        <Header />
        <div className="main d-flex">
          <SideMenu />
          <div className="main-body">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
