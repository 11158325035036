import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  loggedIn: false,
  isNewBusiness: true,
  roleId: "",
  token: "",
  role: "",
  userName: "",
  userEmail: "",
  phoneNo: "",
  phoneCode: "",
  userPermissions: {},
  clientToken: "",
  businessDetails: {
    id: "",
  },
  tokens: {
    token: "",
    refreshToken: "",
  },
};

const userSlice = createSlice({
  name: "user_store",
  initialState: initialState,
  reducers: {
    setNewBusiness(state, action) {
      state.isNewBusiness = action.payload.isNewBusiness;
    },
    setLogin(state, action) {
      state.loggedIn = true;
      state.userName = action.payload.name;
      state.phoneNo = action.payload.phoneNo;
      state.phoneCode = action.payload.phoneCode;
      state.userEmail = action.payload.email;
      state.token = action.payload.token;
    },
    setLogout: () => initialState,
    setClientToken(state, action) {
      state.tokens.token = action.payload.token;
      state.tokens.refreshToken = action.payload.refreshToken;
    },
    setAllBusinessDetails(state, action) {
      state.businessDetails = { ...action.payload };
    },
    setUserEnabling(state, action) {
      let duplicateUserRole = JSON.parse(
        JSON.stringify(state.businessDetails.UserRoles)
      );
      duplicateUserRole[action.payload.index] = action.payload.value;
      state.businessDetails.UserRoles = duplicateUserRole;
    },
  },
});

export const {
  setNewBusiness,
  setLogin,
  setLogout,
  setClientToken,
  setAllBusinessDetails,
  setUserEnabling,
} = userSlice.actions;
export default userSlice.reducer;
