import instagram from "../assets/img/instagram.svg";
import fb from "../assets/img/facebook.svg";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-links">
        <div>
          <NavLink>Contact Us</NavLink>
          {/* <NavLink to="contact_us">Contact Us</NavLink> */}
          <NavLink to="about_us">About Us</NavLink>
        </div>
        <div>
          <NavLink to="terms_and_condition-bms">Terms & Conditions</NavLink>
          <NavLink to="privacy_policy">Privacy Policy</NavLink>
          <NavLink to="security_policy">Securiy Policy</NavLink>
        </div>
        <div>
          <NavLink to="help">Help & Support</NavLink>
        </div>
      </div>
      <div className="footer-copyright">
        <p>ⓒ 2024 www.cliqmenu.com</p>
        <div>
          <NavLink>
            <img src={fb} className="img-fluid" alt="facebook icon" />
          </NavLink>
          <NavLink>
            <img src={instagram} className="img-fluid" alt="instagram icon" />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
