import useLocalStorage from "./useLocalStorage";
import { useAxios } from "./useAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  setNewBusiness,
  setAllBusinessDetails,
} from "../store/reducers/user_store";
import { setLogin } from "../store/reducers/user_store";
import { setProgressBar } from "../store/reducers/config_store";
import { setBusinessDataLoading } from "../store/reducers/settings_store";
import { useNavigate } from "react-router-dom";

export const useBusinessDetails = () => {
  const phoneNo = useSelector((state) => state.userData.phoneNo);
  const phoneCode = useSelector((state) => state.userData.phoneCode);
  const axios = useAxios();
  const dispatch = useDispatch();
  const { getLocalStorageItem } = useLocalStorage();
  const navigate = useNavigate();

  let storedPhoneNo = getLocalStorageItem("phoneNumber");
  let storedCountryCode = getLocalStorageItem("phoneCode");

  const getBusinessDetails = () => {
    let method = "get";
    let storedPhoneNumber = JSON.stringify(storedCountryCode + storedPhoneNo);
    let savedPhoneNumber =
      phoneCode && phoneNo ? JSON.stringify(phoneCode + phoneNo) : "";
    let url = `ownerProfile?TelephoneNumber=${
      savedPhoneNumber
        ? JSON.parse(savedPhoneNumber)
        : JSON.parse(storedPhoneNumber)
    }`;
    dispatch(setBusinessDataLoading({ loading: true }));
    axios
      .request({ method, url })
      .then((res) => {
        if (res.status !== 200) return;
        if (res.data.length === 0) {
          dispatch(setNewBusiness({ isNewBusiness: true }));
          navigate("/app/business");
          return;
        }
        dispatch(setNewBusiness({ isNewBusiness: false }));
        dispatch(setAllBusinessDetails({ ...res.data[0] }));
        let data = {
          name: res.data[0]?.FirstName + " " + res.data[0]?.LastName,
          email: res.data[0]?.Emails[0]?.EmailAddress,
          phoneNo: phoneNo ? phoneNo : storedPhoneNo,
          phoneCode: res.data[0]?.Telephones[0]?.TelephoneCountryCode
            ? res.data[0]?.Telephones[0]?.TelephoneCountryCode
            : "+91",
        };
        dispatch(setLogin(data));
        let progressBarData = {
          businessCreated: res.data[0]?.BusinessDetails?.BusinessName
            ? true
            : false,
          foodTruckCreated: res.data[0]?.Trucks?.length > 0 ? true : false,
          scheduleCreated: res.data[0]?.Trucks?.length > 0 ? true : false,
          menuCreated: res.data[0]?.Trucks?.length > 0 ? true : false,
          userCreated: res.data[0]?.UserRoles?.length > 1 ? true : false,
        };
        dispatch(setProgressBar(progressBarData));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setBusinessDataLoading({ loading: false }));
      });
  };
  return { getBusinessDetails };
};
